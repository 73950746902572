import { track } from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';

import UsernameAndPassword from '../onboarding/username-password/UsernameAndPassword';
// import EnterPhoneNum from '../onboarding/enter-phone-num/EnterPhoneNum';
// import EnterVeriCode from '../onboarding/enter-veri-code/EnterVeriCode';
import InterestSelection from '../onboarding/interest-selection/InterestSelection';
import MediaSelection from '../onboarding/media-selection/MediaSelection';
import NameAndEmail from '../onboarding/name-and-email/NameAndEmail';
import UserLocation from '../onboarding/user-location/UserLocation';

import { authUtils } from '../../../firebase/instance';
import { FEED } from '../../../constants/routes';
import store, { RootState } from '../../../redux/store';
import { 
  updateSignUpState as updateState,
  incrementStep,
  SignUpState,
} from '../../../redux/slices/sign-up/sign-up.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux-hooks';
import './SignUp.css';
import EnterVeriCode from '../onboarding/enter-veri-code/EnterVeriCode';
import EnterPhoneNum from '../onboarding/enter-phone-num/EnterPhoneNum';



const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state: SignUpState = useAppSelector((state: RootState) => state.signUp);

  const retryPhoneAuth = () => {
    const retryCount = state.retryCount;
    if (retryCount < 1) {
      dispatch(updateState({ currentStep: 6, retryCount: retryCount + 1 }));
    }
  };

  const onNext: Function = () => {
    dispatch(incrementStep());
    const newState = store.getState();
    checkStep(newState.signUp);
  }

  const checkStep: Function = (newState: SignUpState) => {
    const nextStep = newState.currentStep;
    if (nextStep === 8) {
      console.log("New User: ", newState.user);
      
      createAccount(newState)
        .then(authUser => {
          const { photoURL, email, uid } = authUser || {};
          const userAuthData = { photoURL, email, uid };
          dispatch(updateState({
            currentStep: 7,
            userAuthData
          }));
        })
        .catch(error => {
          dispatch(updateState({
            currentStep: 1,
            error: { emailInUseError: error.message },
          }));
        });
    } else if (nextStep >= 7){
      // Check auth State
      navigate(FEED);
    }
  };

  const createAccount = async (newState: SignUpState) => {
    const { isGoogleSignIn } = newState.authState;

    if (isGoogleSignIn) {
      const { fullname, username, zipcode, interests, medias } = newState.user;
      const { email } = newState.authState;
      const { uid, photoURL } = newState.userAuthData;

      try {
        await authUtils.doCreateUserWithGoogle(uid, {
          fullname,
          email,
          photoURL,
          username,
          zipcode,
          interests,
          medias,
          following: [],
          politiciansFollowing: [],
          politiciansOpposing: [],
          politiciansSupporting: [],
          propositionsOpposing: [],
          propositionsSupporting: [],
          storiesOpposing: [],
          storiesSupporting: [],
          recentActivity: [],
          about: null,
          title: null
        });
        return { uid, fullname, email, photoURL };
      } catch (error) {
        console.error("Google Sign-Up error:", error);
        throw error;
      }
    } else {
      const { email, password } = newState.authState;
      if (!email || !password) {
        throw new Error("Email and password are required for sign-up.");
      }
  
      try {
        const authUser = await authUtils.doCreateUserWithEmailAndPassword(email, password);
        return authUser.user;
      } catch (error) {
        console.error("Email/Password Sign-Up error:", error);
        throw error;
      }
    }
  };
  
  
  

  const onSubmit = (evt: any) => {
    evt.preventDefault();
  };

  const handleGoogleSignIn = async () => {
    try {
      console.log("Google Sign-In initiated");
      const googleUser = await authUtils.doSignInWithGoogle();

      if (!googleUser.uid) {
        throw new Error("Google User UID is missing");
      }

      const { uid, displayName, email, photoURL } = googleUser;
      const userData = {
        uid,
        fullname: displayName || '',
        email: email || '',
        photoURL: photoURL || '',
        username: '', 
        zipcode: '',
        interests: [],
        medias: [],
        following: [],
        politiciansFollowing: [],
        politiciansOpposing: [],
        politiciansSupporting: [],
        propositionsOpposing: [],
        propositionsSupporting: [],
        storiesOpposing: [],
        storiesSupporting: [],
        recentActivity: [],
        about: null,
        title: null
      };

      // Update the state
      dispatch(updateState({
        currentStep: 3,
        user: userData,
        authState: { 
          email: email || '',
          isGoogleSignIn: true 
        },
        userAuthData: userData
      }));

      // Proceed to the next step (UserLocation)
      onNext();
    } catch (error) {
      console.error("Google Sign-In error:", error);
    }
  };

const handleSignUp = async (isGoogleSignIn: boolean, email?: string, password?: string) => {
  if (isGoogleSignIn) {
    await handleGoogleSignIn();
  } else {
    try {
      if (!email || !password) {
        throw new Error("Email and password are required for sign-up.");
      }

      // Use the existing createAccount function to sign up with email and password
      const newState = store.getState().signUp;
      newState.authState.email = email;
      newState.authState.password = password;

      await createAccount(newState); // Call your existing createAccount function

      onNext(); // Proceed to the next step
    } catch (error) {
      console.error("Error during email/password sign-up:", error);
    }
  }
};




  track("signUpDisplayed");
  
  return (
    <form onSubmit={onSubmit} className="signup-form-container">
      
      {state.currentStep === 1 ? (
        <NameAndEmail
          onNext={onNext}
          emailInUseError={state.error.emailInUseError}
          handleGoogleSignIn={() => handleSignUp(true)}
        />
      ) : null}

      {state.currentStep === 2 ? (
        <UsernameAndPassword
          onNext={onNext}
        />
      ) : null}

      {state.currentStep === 3 ? (
        <UserLocation 
          onNext={onNext} 
        />
      ) : null}

      {state.currentStep === 4 ? (
        <MediaSelection 
          onNext={onNext} 
        />
      ) : null}

      {state.currentStep === 5 ? (
        <InterestSelection
          onNext={onNext}
        />
      ) : null}

      {state.currentStep === 6 ? <EnterPhoneNum onNext={onNext} /> : null}

      {state.currentStep === 7 ? (
        <EnterVeriCode
          onNext={onNext}
          retryCount={state.retryCount}
          retryPhoneAuth={retryPhoneAuth}
        />
      ) : null}

    </form>
  );
}

export default SignUp;
