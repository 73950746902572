import { auth, firestore, provider } from "./firebase-instance";
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";

import { userFollowersCollectionRef, usersCollectionRef } from "./firestore-collection-refs";
import { doc, DocumentReference, WriteBatch, writeBatch, setDoc } from "firebase/firestore";
import { UserFollowersDoc } from "../../interfaces/comment-entities.interfaces";
import { AccountState } from "../../redux/slices/account/account.slice";
import { SignUpState } from "../../redux/slices/sign-up/sign-up.slice";


export const doCreateUserWithEmailAndPassword = async (email: string, password: string) =>{
  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Error during email/password creation:", error);
    throw error;
  }
};

export const doSignInUserWithEmailAndPassword = async (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password);

export const doSignOut = (): Promise<void> => signOut(auth);

export const doPasswordReset = async (email: string) =>
  sendPasswordResetEmail(auth, email);

export const doPasswordUpdate = async (password: string) =>
  auth.currentUser ? updatePassword(auth.currentUser, password) : null;

export const verifyPasswordResetCodeWrap = (actionCode: string) =>
  verifyPasswordResetCode(auth, actionCode);

export const confirmPasswordResetWrap = async (actionCode: string, newPassword: string) =>
  confirmPasswordReset(auth, actionCode, newPassword);

export const doFetchSignInMethodsForEmail = async (email: string) =>
  fetchSignInMethodsForEmail(auth, email);

export const doSignInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential?.accessToken;
    // The signed-in user info.
    const user = result.user;
    return user;
  } catch (error) {
    console.error("Google Sign-In error: ", error);
    throw error;
  }
};

export const doCreateUserWithGoogle = async (uid: string, userData: any) => {
  try {
    if (!uid) {
      throw new Error("UID is undefined or empty. Cannot create Firestore document.");
    }
    const userRef = doc(usersCollectionRef, uid);
    // Store user details in Firestore
    await setDoc(userRef, {
      ...userData,
      userId: uid
    });

    console.log("User created with Google successfully");
  } catch (error) {
    console.error("Error creating user with Google: ", error);
    throw error;
  }
};

interface CBandParam {
  cb: Function;
  param: any;
  userParam?: boolean;
}

interface MonitorParam {
  loggedIn: CBandParam;
  loggedOut: CBandParam;
  username: string | null;
}

export const monitorAuthState = 
  async ({ loggedIn, loggedOut, username }: MonitorParam) => {
    // https://firebase.google.com/docs/auth/web/manage-users
    const { cb: loggedInCB, param: loggedInParam, userParam = false } = loggedIn;
    const { cb: loggedOutCB, param: loggedOutParam } = loggedOut;
    const [location, location2] = loggedOutParam;
    const curLocation = window.location.pathname;
    return onAuthStateChanged(auth, user => {
      // console.log({ curLocation, user, location, location2, userParam, loggedInParam });
      if (user) {
        // Need to check if user is within redux...
        if (curLocation === location || curLocation === location2 || !username) { 
          userParam ? loggedInCB(user, loggedInParam) : loggedInCB(loggedInParam);
        }
      } else {
        window.location.pathname === location2 ? loggedOutCB(location2) : loggedOutCB(location);
      }
    })
  }

export const signUpBatchReq = async (userId: string, userSignUp: SignUpState) => {
  try {
    const { 
      fullname, 
      username, 
      zipcode, 
      medias, 
      interests 
    } = userSignUp.user;

    const {
      email,
      isGoogleSignIn,
      phoneNumber
    } = userSignUp.authState;

    const userFollowersDocRef: DocumentReference = 
      doc(userFollowersCollectionRef, userId);

    const usersCollectionDocRef: DocumentReference = 
      doc(usersCollectionRef, userId);

    const batch: WriteBatch = writeBatch(firestore);

    const newUserFollower: UserFollowersDoc = {
      lastComment: null,
      followers: [],
      recentComments: []
    }

    const newUser: AccountState = {
      fullname: fullname || userSignUp.userAuthData.displayName || '',
      username: username || '',
      zipcode: zipcode || '',
      medias: medias || [],
      interests: interests || [],
      email: email || userSignUp.userAuthData.email || '',
      userId,
      title: null,
      about: null,
      following: [],
      politiciansFollowing: [],
      photoURL: isGoogleSignIn ? userSignUp.userAuthData.photoURL ?? null : null,
      politiciansSupporting: [],
      politiciansOpposing: [],
      propositionsSupporting: [],
      propositionsOpposing: [],
      storiesSupporting: [],
      storiesOpposing: [],
      recentActivity: [],
      phoneNumber: phoneNumber || null
    }

    console.log("New user data:", newUser);
    console.log("New user follower data:", newUserFollower);

    batch.set(userFollowersDocRef, newUserFollower);
    batch.set(usersCollectionDocRef, newUser);

    await batch.commit();
    console.log("Batch commit successful");
  } catch(e){
    console.error("Sign up batch request failed: " + e);
    throw e;
  }
}
