import { useState, useEffect } from 'react';
import TextInput from '../../../shared/text-input/TextInput';
import OnboardingCardLayout from '../card-layout/OnboardingCardLayout';
import './EnterPhoneNum.css';
import { auth } from '../../../../firebase/instance';
import { PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { updateAuthState } from '../../../../redux/slices/sign-up/sign-up.slice';
import { useAppDispatch } from '../../../../redux/hooks/redux-hooks';

interface Param {
  onNext: Function;
}

const EnterPhoneNum = ({ onNext }: Param) => {

  const [phoneState, updatePhoneState] = useState('');
  const dispatch = useAppDispatch();

  const [recaptchaId] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);

  const checkValid = () => {
    return (
      phoneState.length === 10 && 
      recaptchaId !== null && 
      recaptcha !== null
    );
  };

  const handleOnClick = () => {

    if (!recaptcha) {
      console.log("Recaptcha not initialized.");
      return;
    }

    const phoneAuth = new PhoneAuthProvider(auth);
    const phoneNum = `+1${phoneState}`;

    phoneAuth.verifyPhoneNumber(phoneNum, recaptcha)
      .then((verificationId) => {
        dispatch(updateAuthState({
          phoneNumber: phoneNum,
          verificationId: verificationId
        }));
        onNext();  // Proceed to the next step
      })
      .catch((error) => console.log("Error during phone verification: " + error.message));
  };

  // useEffect(() => {
  //   async function recaptchaFlow() {
  //     const recaptcha: RecaptchaVerifier = new RecaptchaVerifier(
  //       'recaptcha-container', {}, auth
  //     )
  //     setRecaptcha(recaptcha);
  //     const recaptchaId = await recaptcha.verify();
  //     setRecaptchaId(recaptchaId);
  //   }

  //   recaptchaFlow();
  // }, [signUp.retryCount]);

  useEffect(() => {
    if (!recaptcha && typeof window !== "undefined") {  // Ensure recaptcha is only initialized once
      const verifier = new RecaptchaVerifier(
        'recaptcha-container', {
          size: 'normal',
          callback: (response: string) => {
            console.log('Recaptcha resolved');
          },
          'expired-callback': () => {
            console.log('Recaptcha expired, resetting it');
            verifier.clear();  // Clear expired verifier
            setRecaptcha(null);  // Reset recaptcha state
          },
        },
        auth
      );
      verifier.render().then(() => {
        setRecaptcha(verifier); // Store the recaptcha verifier in state
      });
    }

    // Cleanup to avoid multiple instances
    return () => {
      if (recaptcha) {
        recaptcha.clear();
        setRecaptcha(null);
      }
    };
  }, [recaptcha]);
  

  const headerText = "ENTER YOUR MOBILE NUMBER TO VERIFY YOUR ACCOUNT";
  const buttonLabel = "SEND TEXT";

  return (
    <OnboardingCardLayout
      headerText={headerText}
      content={
        <div>
          <img
            className="onboarding-svg"
            alt="verify"
            src="/img/onboarding/verify.svg"
          />
          <p className="sub-title">
            CONNECT AND SHARE YOUR PROSPECTIVE WITH A VERIFIED NETWORK
          </p>
          <TextInput
            placeholder="(000) 000-0000"
            type="tel"
            value={phoneState}
            onTextInputChange={(event) => {
              updatePhoneState(event.target.value);
            }}
          />
          <p className="under-input-text">VERIFIED THROUGH TEXT</p>
          <div id="recaptcha-container"></div>
        </div>
      }
      displayBackBtn={false}
      buttonParams={
        { label: buttonLabel, onClick: handleOnClick, disabled: !checkValid() }
      }
    />
  );
};

export default EnterPhoneNum;
