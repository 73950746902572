import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UnknownObject } from "../../../interfaces/global";
import { updateFeedData } from "../../thunks/news-stories/stories.helper-functions";
import { processStoryIdForDB } from "../../../util/util.functions";

interface Feed {
  isLoading: boolean;
  pageNum: number;
  mainFeed: any[];
  duplicateDataById: UnknownObject;
  lastComment: null;
}

const initialState: Feed = {
  isLoading: false,
  mainFeed: [],
  duplicateDataById: {},
  lastComment: null,
  pageNum: 1,
};

export const mixedFeed = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    mix_reset_page_counter: (state) => {
      state.pageNum = 0;
    },
    mix_increment_page_counter: (state) => {
      state.pageNum += 1;
    },
    mix_updateFeed: (state, action: PayloadAction<any[]>) => {
      state.mainFeed = updateFeedData({
        feed: state.mainFeed, feedItemsChanged: action.payload
      })
    },
    mix_saveFeedWithDBDataScroll: (state, action) => {
      const { feed, pagination, duplicateDataById, lastComment } = action.payload;
      // console.log({ feed }, "<-- feed in reducer");
      if (pagination > 1) {
        state.mainFeed.push(...feed);
      } else {
        state.mainFeed = feed;
      }
      state.duplicateDataById = duplicateDataById;
      state.lastComment = lastComment;
    },
    update_feed_item_data: (state, action) => {
      const { id, data } = action.payload;
      state.mainFeed = state.mainFeed.map((item, idx) => { 
        const isStoryComment = item?.data?.entity?.entityData?.url;
        const isStory = item?.data?.entityData?.url;
        const isTrue = processStoryIdForDB(isStory || isStoryComment) === id;
        if (isTrue && isStory) {
          return { data, score: item.score };
        } else if (isTrue && isStoryComment) {
          return { data: { ...item.data, entity: { ...data }}, score: item.score}
        }
        return item
      });
    }
  }
});

export const { 
  mix_reset_page_counter,
  mix_increment_page_counter,
  mix_saveFeedWithDBDataScroll,
  mix_updateFeed,
  update_feed_item_data
} = mixedFeed.actions;

export default mixedFeed.reducer;
