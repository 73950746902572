import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../interfaces/global";
import BillProfileSection from "./BillProfileSection";
import Loader from "../../shared/loading/loader";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/instance";
import "./BillInfo.css";

interface BillInfoParam {
  billData: UnknownObject;
  entityData: UnknownObject;
  showHtml: boolean;
  entityInfo: UnknownObject;
}

const BillInfo = () => {
  const context = useOutletContext<BillInfoParam>();
  const [htmlContent, setHtmlContent] = useState("");
  const { showHtml } = context;
  const { isLoading } = context.billData;

  const { entityData } = context.billData?.data || {};
  const { 
    members, 
    dateIssued, 
    currentChamber, 
    download,
  } = entityData || {};
  
  useEffect(() => {
    if (showHtml && download?.xmlLink) {
      const fetchXmlLink = httpsCallable(functions, 'fetchBillXmlLink');
      fetchXmlLink({ packageId: download.xmlLink })
        .then((result: any) => {
          console.log({ result })
          if (result.data.text) {
            setHtmlContent(result.data.text)
          }
        })
        .catch((error: any) => console.error('No HTML content found:', error));
    }
  }, [showHtml, download?.xmlLink]);
  
  if (isLoading) return <Loader isFull={true} />

  const sponsored = (members || []).find((member: any) => member.role === 'SPONSOR');
  
  const formatDate = (isoString: string): string => {
    const date = new Date(isoString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC'
    });
  };

  return (
    <div>
      {showHtml ? (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      ) : (
        <>
          <BillProfileSection
            label="Category"
            content={entityData?.category}
            showMore={false}
          />
          <BillProfileSection
            label="Collection"
            content={entityData?.collectionName}
            showMore={false}
          />
          <BillProfileSection
            label="Congress Number"
            content={entityData?.congress}
            showMore={false}
          />
          <BillProfileSection
            label="Congress Session"
            content={entityData?.session}
            showMore={false}
          />
          <BillProfileSection
            label="Last Action Listed"
            content={entityData?.lastModified ? formatDate(entityData?.lastModified) : "No Information"}
            showMore={false}
          />
          <BillProfileSection
            label="Sponsored By"
            content={sponsored?.memberName}
            showMore={false}
          />
          <BillProfileSection
            label="Current Chamber"
            content={currentChamber || "No Information"}
            showMore={false}
          />
          <BillProfileSection
            label="Date Introduced"
            content={dateIssued}
            showMore={false}
          />
        </>
      )}
    </div>
  )
}

export default BillInfo;