import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRedirects } from "../../../../hooks/useNav";
import BallotSubLinks from "./BallotSubLinks";
// import BallotTopLinks from "./BallotTopLinks";
import "./Navigation.css";

interface Param {
  id: string;
  links: any[];
  onNavigationChange?: Function;
  ballotTab: string;
  subBallotTab: any;
}

const Navigation = ({
  links,
  ballotTab,
  subBallotTab,
}: Param) => {

  const location = useLocation();

  const [state, setState] = useState({
    ballotTab,
    subBallotTab,
  });

  useRedirects({
    redirects: [
      { from: '/ballot', to: '/ballot/local'}, 
      { from: '/ballot/federal', to: '/ballot/federal/politicians'},
      { from: '/ballot/state', to: '/ballot/state/politicians'},
    ]
  });

  useEffect(() => {
    const paths = location.pathname.split('/').filter(path => !!path);
    setState(prev => ({
      ...prev,
      ballotTab: paths[1],
      subBallotTab: paths[2]
    }))
  }, [location]);

  return (
    <nav className="nav">
      {/* <div id="header" className="div-header">
        {
          <BallotTopLinks 
            links={links} 
            // onNavChange={onNavigationChange}
            manageState={{ state, setState }}
          />
        }
      </div> */}
      {
        <BallotSubLinks
          links={links} 
          // onNavChange={onNavigationChange}
          manageState={{ state, setState }}
        />
      }
    </nav>
  );
}

export default Navigation;
