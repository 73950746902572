import { Link } from 'react-router-dom';
import './NameAndEmail.css'
import { MouseEvent, useState, useEffect } from 'react';
import TextInput from '../../../shared/text-input/TextInput';
import HeaderText from '../../../shared/header-text/HeaderText';
import PrimaryButton from '../../../shared/primary-button/PrimaryButton';
import { authUtils } from '../../../../firebase/instance';
import { UnknownObject } from '../../../../interfaces/global';
import { useAppDispatch } from '../../../../redux/hooks/redux-hooks';
import {
  updateUserInfo,
  updateAuthState
} from '../../../../redux/slices/sign-up/sign-up.slice';
import { SIGN_IN } from '../../../../constants/routes';
interface Param {
  onNext: Function;
  emailInUseError: string;
  handleGoogleSignIn: () => void; // Add handleGoogleSignIn as a prop
}

interface State {
  error: UnknownObject;
}

const NameAndEmail = ({ onNext, emailInUseError='', handleGoogleSignIn }: Param) => {
  const [state, setState] = useState({ name: '', email: ''});
  const dispatch = useAppDispatch();

  const [errorState, setErrorState] = useState<State>({
    error: {
      emailInUseError
    }
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const validateInput = async () => {
    let error = null;
    const { name, email } = state;
    let signInMethods = await authUtils.doFetchSignInMethodsForEmail(email);

    if(signInMethods.length > 0) {
      error = "emailExists";
    }
    else if (!email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
      error = "email";
    }
    else if (name.length < 1) {
      error = "fullname";
    }
    return error;
  };

  const handleClick = (e: MouseEvent) => {
    validateInput().then(error => {
      switch (error) {
        case "fullname":
          setErrorState({
            error: {
              ...errorState.error,
              fullnameError: "Please enter a valid fullname.*",
            },
          });
          break;
        case "email":
          setErrorState({
            error: {
              ...errorState.error,
              emailError: "Please enter a valid email.*",
            },
          });
          break;
        case "emailExists":
          setErrorState({
            error: {
              ...errorState.error,
              emailError: "This account already exists.*",
            },
          });
          break;
        case null:
          dispatch(updateUserInfo({
            fullname: state.name
          }));
          dispatch(updateAuthState({
            email: state.email
          }));
          onNext();
          break;
        default:
          throw new Error(`unknown error ${error}`);
      }
    }).catch(e => {
      console.error(e);
    });
  };

  
  return (
    <div className='section-container'>
      <div className='name-email-wrapper'>
        <HeaderText
          styleColor='light'
          headerText='Political Visibility'
          subHeaderText='ISSUES THAT MATTER'
        />
        <div>
          <TextInput
            styleColor='light'
            placeholder='FULL NAME'
            value={state.name}
            onTextInputChange={(event) =>
              setState({ ...state, name: event.target.value })
            }
            errorMessage={errorState.error.fullnameError}
            errorMessageColor='white'
          />
          <TextInput
            styleColor='light'
            placeholder='EMAIL'
            value={state.email}
            onTextInputChange={(event) => {
              setState({ ...state, email: event.target.value })
            }}
            errorMessage={errorState.error.emailError}
            errorMessageColor='white'
          />
          <div className='PrimaryButton'>
            <PrimaryButton label='CREATE ACCOUNT' onClick={handleClick} />

          
          
          <button className="gsi-material-button" onClick={handleGoogleSignIn}>
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents"  style={{fontSize: '14px'}}>Sign in with Google</span>
            </div>
          </button>
          </div>

        </div>
        <div className='mobilize-logo-container'>
          <img
            className='mobilize-logo'
            alt='Mobilize Logo'
            src='/img/mobilizeIconWhitetext.svg'
          />
        </div>
      </div>

      <div className='footer'>
        <p className='footer-text'>Already have an account?</p>
        <Link to={SIGN_IN}>
          <span className='login-link'>Login.</span>
        </Link>
      </div>
    </div>
  );
};

export default NameAndEmail;
