import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// import { connectAuthEmulator } from "firebase/auth";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBmgKimlm1u4TQO6eQapfvIAVegVYAmsTo",
  authDomain: "mobilize-15543.firebaseapp.com",
  databaseURL: "https://mobilize-15543.firebaseio.com",
  projectId: "mobilize-15543",
  storageBucket: "mobilize-15543.appspot.com",
  messagingSenderId: "60227274745",
};

const firebase = initializeApp(config);

const firestore = getFirestore(firebase);
const functions = getFunctions(firebase);
const provider = new GoogleAuthProvider();
const auth = getAuth(firebase);

// use below for local development - comment out for production
console.log("version 1.0")
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_ENV_MODE === "development") {
  // connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  // connectAuthEmulator(auth, 'localhost');
}

export { firebase, auth, firestore, functions, provider };
