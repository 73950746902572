import { createBrowserRouter } from 'react-router-dom';
// import { createHashRouter } from 'react-router-dom';
import App from '../App';
import * as routes from '../constants/routes';
import Feed from '../components/main-feed/Feed';
import Ballot from '../components/ballot/Ballot';
import Headlines from '../components/headlines/Headlines';
import People from '../components/people/People';
import Account from '../components/account/Account';
import BillProfile from '../components/ballot/bill-profile/BillProfile';
import CommentsContainer from '../components/comments/comments-container/CommentsContainer';
import PasswordForget from '../components/password/PasswordForget';
import PasswordReset from '../components/password/PasswordReset';
import PoliticianProfile from '../components/politician/politician-profile/PoliticianProfile';
import PropositionProfile from '../components/ballot/proposition/PropositionProfile';
import SignIn from '../components/authentication/sign-in/SignIn';
import SignUp from '../components/authentication/sign-up/SignUp';
import { commentsLoader } from './route-loaders';
import EditAccount from '../components/account/edit-account/EditAccount';
import SharedLayoutWithNav from '../components/shared-layout/SharedLayoutWithNav';
import Bio from '../components/politician/politician-profile/Bio';
import FeedForPolitician from '../components/politician/politician-profile/FeedForPolitician';
import Local from '../components/ballot/gov-branches/local/Local';
import State from '../components/ballot/gov-branches/state/State';
import Federal from '../components/ballot/gov-branches/federal/Federal';
import Executive from '../components/ballot/gov-branches/executive/Executive';
import Congress from '../components/ballot/gov-branches/congress/Congress';
import Posts from '../components/account/Posts';
import PoliticianCommentsFeed from '../components/politician/politician-profile/PoliticianCommentsFeed';
import BillCommentsFeed from '../components/ballot/bill-profile/BillCommentsFeed';
import BillInfo from '../components/ballot/bill-profile/BillInfo';
import Followers from '../components/account/Followers';
import Following from '../components/account/Following';
import PropositionInfo from '../components/ballot/proposition/PropositionInfo';
// Switching out createBrowserRouter for createHashRouter for some reason...
export const router = createBrowserRouter([
  {
    path: routes.LANDING,
    element: <App />,
    errorElement: <div>ERORR!</div>,
    children: [
      {
        path: routes.LANDING,
        element: <SharedLayoutWithNav />,
        children: [
          {
            path: routes.FEED,
            element: <Feed />
          },
          {
            path: routes.BALLOT,
            element: <Ballot />,
            children: [
              {
                path: `${routes.BALLOT}/local`,
                element: <Local />
              },
              {
                path: `${routes.BALLOT}/state`,
                element: <State />,
                children: [
                  {
                    path: `${routes.BALLOT}/state/politicians`,
                    // element: <PoliticianPage />
                    element: <Executive />
                  },
                  {
                    path: `${routes.BALLOT}/state/bills`,
                    // element: <PropositionPage />
                    element: <Congress />
                  }
                ]
              },
              {
                path: `${routes.BALLOT}/federal`,
                element: <Federal />,
                children: [
                  {
                    path: `${routes.BALLOT}/federal/politicians`,
                    element: <Executive />
                  },
                  {
                    path: `${routes.BALLOT}/federal/bills`,
                    element: <Congress />
                  }
                ]
              }
            ]
          },
          {
            path: routes.HEADLINES,
            element: <Headlines />
          },
          {
            path: routes.PEOPLE,
            element: <People />
          }
        ]
      },
      {
        path: `${routes.BILL_PROFILE}`,
        element: <BillProfile />,
        children: [
          {
            path: `${routes.BILL_PROFILE}/info`,
            element: <BillInfo />
          },
          {
            path: `${routes.BILL_PROFILE}${routes.COMMENTS}`,
            element: <BillCommentsFeed />,
            loader: commentsLoader
          }
        ]
      },
      {
        path: routes.PROPOSITION_PROFILE,
        element: <PropositionProfile />,
        children: [
          {
            path: `${routes.PROPOSITION_PROFILE}/info`,
            element: <PropositionInfo />
          },
          {
            path: `${routes.PROPOSITION_PROFILE}${routes.COMMENTS}`,
            element: <BillCommentsFeed />,
            loader: commentsLoader
          }
        ]
      },
      {
        path: routes.ACCOUNT,
        element: <Account />,
        children: [
          {
            path: `${routes.ACCOUNT}/posts`,
            element: <Posts />
          },
          {
            path: `${routes.ACCOUNT}${routes.FOLLOWERS}`,
            element: <Followers />
          },
          {
            path: `${routes.ACCOUNT}${routes.FOLLOWING}`,
            element: <Following />
          }
        ]
      },
      {
        path: `${routes.ACCOUNT}/edit`,
        element: <EditAccount />
      },
      {
        path: routes.COMMENTS,
        element: <CommentsContainer />,
        loader: commentsLoader
      },
      {
        path: routes.PASSWORD_FORGET,
        element: <PasswordForget />
      },
      {
        path: routes.PASSWORD_RESET,
        element: <PasswordReset />
      },
      {
        path: routes.POLITICIAN_PROFILE,
        element: <PoliticianProfile />,
        children: [
          {
            path: `${routes.POLITICIAN_PROFILE}`,
            element: <Bio />
          },
          {
            path: `${routes.POLITICIAN_PROFILE}/news`,
            element: <FeedForPolitician />
          },
          {
            path: `${routes.POLITICIAN_PROFILE}/${routes.COMMENTS}`,
            element: <PoliticianCommentsFeed />,
            loader: commentsLoader
          }
        ]
      },
      {
        path: routes.SIGN_IN,
        element: <SignIn />
      },
      {
        path: routes.SIGN_UP,
        element: <SignUp />
      }
    ]
  },
  
]);
