import "./HeaderText.css";

interface HeaderTextParam {
  styleColor: string;
  headerText: string;
  subHeaderText?: string;
  fontSize?: string;
}

const HeaderText = ({ styleColor, headerText, subHeaderText, fontSize }: HeaderTextParam) => {
  const className =
    styleColor === "light" ? "text-header-light" : "text-header-dark";

  return (
    <div className="text-header-container">
      <h1 className={`header-text ${className}`}style={{ fontSize: fontSize }}>{headerText}</h1>
      <h2> {subHeaderText}</h2>
    </div>
  );
}

export default HeaderText;
