import { forwardRef } from "react";
import { PerigonEntityData } from "../../../interfaces/comment-entities.interfaces";
// import { UnknownObject } from "../../../interfaces/global";
import '../story.css';

interface Param {
  date: string;
  entityData: PerigonEntityData;
  idx: number | undefined;
  renderContent: any;
  showSourceIcon: boolean;
}
// TODO: add more domains to this map 
const domainToLogoMap: { [key: string]: string } = {
  'apnews.com': 'associated-press',
  'foxnews.com': 'fox-news',
  'time.com': 'time'
};

const domainToNameMap: { [key: string]: string } = {
  'apnews.com': 'Associated Press',
  'foxnews.com': 'Fox News',
  'time.com': 'Time'
};

const NewsContent = forwardRef<HTMLParagraphElement, Param>((props, ref) => {
  const { date, entityData, idx, renderContent, showSourceIcon } = props;

  const getLogoName = (domain: string) => {
    return domainToLogoMap[domain] || 'default';
  };

  return (
    <div>
      <div className="news-content-container">
        <p className="news-content" key={idx} ref={ref}>
          {renderContent} <a href={`${entityData.url}`}>... more</a>
        </p>
      </div>

      {/* souce icon and news date */}
      <div className="news-source-date">
        <div className="news-source-container">
          {showSourceIcon && entityData.source?.domain ? (
            <img
              src={`../../img/medias/${getLogoName(entityData.source?.domain)}.svg`} 
              alt={entityData.source.domain}
              className="source-icon"
              onError={(e) => {
                e.currentTarget.src = `../../img/medias/${entityData.source.id}.png`;
              }}
            />
          ) : null}
          <span className="news-source">
            {entityData.source?.domain ? domainToNameMap[entityData.source.domain] : ''}
          </span>
        </div>
        <span className="publish-date">{` ${date}`}</span>
      </div>
    </div>
  )
})

export default NewsContent;