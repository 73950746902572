import { useEffect, useState } from "react";
// import FooterTabBar from "../../shared/FootTabBar";
import PoliticianPage from "../../../politician/politician-profile/PoliticianPage";
import PropositionPage from "../../proposition/PropositionPage";
import { Outlet, useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../../interfaces/global";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { savePropositions } from "../../../../redux/thunks/politicians-ballot/politicians.thunks";
import { AccountState } from "../../../../redux/slices/account/account.slice";


interface Param {
  data?: any;
  currentSubTab?: any;
  account?: AccountState
}

const State = ({ data, account }: Param) => {
  const [state] = useState<{ currentTab: string }>({
    currentTab: "Politicians",
  });

  const dispatch = useAppDispatch();
  const propositions = useAppSelector(store => store.politicians.propositions);

  const { 
    politicians, account: accountContxt,
  } = useOutletContext() as UnknownObject;
  data = data ? data : politicians?.data?.state;

  if (!account) account = accountContxt as AccountState;

  useEffect(() => {
    if (state.currentTab === "Bills" && propositions.jurisdiciton !== data.name) {
      console.log("Dispatching propositions");
      dispatch(savePropositions(data.name));
    }
  }, [state, dispatch, propositions.jurisdiciton, data]);

  const offices = [
    ...(data?.offices ?? []),
    ...(data?.congressionalDistrict?.offices ?? []),
  ];

  

  return (
    <>
      <Outlet context={{ data, account }} />
      {state.currentTab === "Politicians" ? (
        <PoliticianPage offices={offices} />
      ) : (
        <PropositionPage data={propositions.data} jurisdiction={data.name} />
      )}
    </>
  );
};

export default State;
