import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../../../firebase/instance";
import { fetchHeadlinesFromNewsApi } from "../../../firebase/stories/fetch-stories";
import { processStoryIdForDB } from "../../../util/util.functions";
import { updateFeedAppData } from "./stories.helper-functions";
import { httpsCallable } from "firebase/functions";
// import { fetchFeed, fetchPerigonFeed } from "../../../api/api.news-stories";
// import { fetchMockData } from "../../../api/mockApi/fetchMock";
// import { feed, singleStory } from "./tempfeeddata-mock";


export const saveFeedWithDBData_bak = createAsyncThunk(
  'stories/saveFeedWithDBData',
  async (feed: any[]) => {
    return addFirebaseDataToStories(feed);
  }
);

export const saveHeadlinesWithDbData = createAsyncThunk(
  'stories/saveHeadlinesWithDbData',
  async () => {
    const headlines = await fetchHeadlinesFromNewsApi();
    return addFirebaseDataToStories(headlines);
  }
);

// The feed that's passing through here is from api...
// Will need to add listener to either the storyDataRef doc or the query?
// If anything it should work for the doc - testing...

// 2.9.23 - this needs refactoring
export const addFirebaseDataToStories = async (feed: any[]) => {
  const data = [];

  // Here we are getting app data - right now it looks like
  for(let i = 0; i < feed.length; i++){

    let feedItem = feed[i];
    // This is allowing us to skip over anything within our feed that isn't a story...
    // Although, it would seem that we aren't passing anything into this function that isn't a story...

    // Before Perigon added...
    // if (!feedItem.url) continue;
    // After Perigon added...
    if (feedItem.entityData) {
      if (!feedItem.entityData.url) continue;
    } else {
      if (!feedItem.url) continue;
    }
    
    const id = processStoryIdForDB(feedItem.url || feedItem?.entityData?.url);

        // change
        const getStoryData = 
          httpsCallable(functions, "fetchStoryDataAndRemoveSupportingArrays");

        const storyAppData = getStoryData({ id });
        if (!storyAppData) continue;

    // const storyDataRef: DocumentReference = 
    //   doc(storiesCollectionRef, id);
        
    // could we batch and get a document reference and query at the same time
    // data.push(query(storiesCollectionRef, where('appData.id', '==', id)));
    // data.push(getDoc(storyDataRef));
      data.push(storyAppData)
  }

  const storyDataList = await Promise.allSettled(data);

  // This function parses the appData from the promises and attaches it to the appropriate story.
  const parseOutData = (storyDataList: any[], feed: any[]) => {
    storyDataList.forEach((story, idx) => {
      // Here we'll probably want to strip the story data if it's added in the data and it's duplicated?
      const data = story.value.data;
        // Change
        // const data = story;
      if (data) {
        feed[idx] = data
      } else {
        // TO DO - SAVE STORY OR MARK FOR LISTENER? Check other TO DO
        // Save Story
        feed[idx] = { entityData: feed[idx], appData: null }
      }
    });
  }

  parseOutData(storyDataList, feed);
  return updateFeedAppData({ currentFeed: feed });
}

export const getStoryData = async (id: string) => {
  // change
  const getStoryData = 
    httpsCallable(functions, "fetchStoryDataAndRemoveSupportingArrays");

  const data = await getStoryData({ id });
  return data;
}
