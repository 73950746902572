import { useEffect, useCallback, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/redux-hooks';
import { RootState } from '../../redux/store';
import Story from '../story/Story';
import './feed.css';
// import NoResult from '../shared/no-result/NoResult';
import FeedWrap from './feed.comments/FeedWrap';
import useInfiniteFeedScroll from '../../hooks/useInfiniteFeedScroll';
import { getFeedAndDbData } from '../../api/api.news-stories';
import Loader from '../shared/loading/loader';
import { sortFeed } from './utils/feedSort';
import { fetchCommentsFeed } from '../../firebase/comments/user-followers/user-followers.comments';
import { 
  mix_increment_page_counter, 
  mix_saveFeedWithDBDataScroll,  
} from '../../redux/slices/feed/mixedFeed.slice';
import { filterComments } from './utils/filterComments';
import { saveCommentsFeedWithLastComment } from '../../redux/slices/comments/comments.slice';
import NoResult from '../shared/no-result/NoResult';
import CreateCommentJsx from './utils/CreateCommentJsx';

const Feed = () => {
  const { comments: storeComments, account, mixedFeed } = 
    useAppSelector((state: RootState) => state);
  
  const dispatch = useAppDispatch();

  const [replyBoxIsOpen, toggleShowReplyBox] = useState(false);
  const initialFeedAdded = useRef(false);
  //////// Testing ////////
  // --> Fires immediately
  const addFeedToStore = useCallback(async () => {
    const { data, pagination } = await getFeedAndDbData({ account, num: 1 });
    const { duplicateDataById, lastComment, comments } = await fetchCommentsFeed({ 
      account,
      storiesFeed: data
    });

    // How are we getting 
    console.log({ comments, data }, "<--- COMMENTS");

    // sort
    const sortedFeed = 
      sortFeed({
        stories: data,
        comments: comments,
        duplicateDataById
      });

    dispatch(saveCommentsFeedWithLastComment({ comments, lastComment, duplicateDataById }));
    dispatch(mix_saveFeedWithDBDataScroll({ 
      feed: sortedFeed, 
      pagination,
      duplicateDataById,
      lastComment
    }));
    dispatch(mix_increment_page_counter());

  },  [account, dispatch]);
  
  useEffect(() => {
    if (account.userId && !initialFeedAdded.current) {
      initialFeedAdded.current = true
      addFeedToStore();
    }
  }, [account.userId,  addFeedToStore]);


  // THIS ISN'T HANDLING SUBSCRIPTIONS TO COMMENTS
  // useHandleFeedSubscriptions({
  //   newsfeed: mixedFeed.mainFeed, 
  //   saveAndReturnStoryQueries,
  //   reduxAction: mix_updateFeed,
  //   dispatch
  // });
  // <--

  // --> Fire only on scroll... it's waiting
  const feedRef = useRef(null);

  const infiniteFeedApiCall = useCallback(async () => {
    console.log("infiniteFeedApiCall...!!!");
    const { data, pagination } = await getFeedAndDbData({ account, num: mixedFeed.pageNum });
    const { duplicateDataById, lastComment, comments } = await fetchCommentsFeed({ 
      account,
      storiesFeed: data 
    });
    /* 
      Filter comments
    */
    dispatch(saveCommentsFeedWithLastComment({ comments, lastComment, duplicateDataById }));
    /// sort
    const filteredComments = filterComments(storeComments.commentsFeed, comments);
    const sortedFeed = 
      sortFeed({
        stories: data,
        comments: filteredComments
      });

    return { feed: sortedFeed, pagination, duplicateDataById, lastComment };
  }, [account, mixedFeed.pageNum, dispatch, storeComments.commentsFeed]);

  const [status] = useInfiniteFeedScroll({
    feedReference: feedRef, 
    apiCall: infiniteFeedApiCall,
    saveFeedRedux: mix_saveFeedWithDBDataScroll, 
    incrementCounterRedux: mix_increment_page_counter,
  });
  // <--
  ///////////////////////
  const mergeAndReturnStoriesAndComments = useCallback(() => {
    const feed = mixedFeed.mainFeed.map((post: any, idx: number) => {
      const { data } = post;
      if (data.comment) {
        // if (idx === 1) {
          return CreateCommentJsx({ 
            data, 
            stateManagers: { replyBoxIsOpen, toggleShowReplyBox },
            idx
          });

        // }
      } 
      else {
        // if (idx > 2) return null;
        return <Story key={idx} data={data} />
      }
    });
    // Replace feed data with JSX
    return feed;
  }, [mixedFeed, replyBoxIsOpen]);
  
  return (
    <FeedWrap ref={feedRef}>
      {mergeAndReturnStoriesAndComments()}
      {status === 'pending' ? <Loader /> : mixedFeed.mainFeed.length < 0 ? <NoResult /> : null}
    </FeedWrap>
  )
}

export default Feed;