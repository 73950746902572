import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountState } from '../../redux/slices/account/account.slice';
import { processStoryIdForDB } from '../../util/util.functions';
import './story.css';
import './hairline.css';
import CommentAndSupport from '../shared/comment-support/CommentAndSupport';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/redux-hooks';
import { handleSupport } from '../../firebase/shared/handle-support';
import OptionModal from '../shared/option-modal/option-modal';
import { CommentWindow } from '../comments/comment-window/CommentWindow';
import { ENTITY_TYPE } from '../../constants/entities';
import NewsImageContainer from './story-jsx/NewsImageContainer';
import NewsContent from './story-jsx/NewsContent';
import NewsTitle from './story-jsx/NewsTitle';
import { getStoryData } from '../../redux/thunks/news-stories/stories.thunks-action-creators';
import { update_feed_item_data } from '../../redux/slices/feed/mixedFeed.slice';

interface StoryParam {
  data: any;
  idx?: number;
  userAccount?: AccountState;
  showSourceIcon?: boolean;
  showCommentFeature?: boolean;
  isFeedComment?: boolean;
  date?: any;
  isComment?: boolean;
}

const Story = ({
  data,
  date,
  idx,
  isFeedComment = false,
  showCommentFeature = true,
  showSourceIcon = true,
  isComment = false
}: StoryParam) => {
  const userAccount = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const { appData, entityData } = data;

  console.log('Story Data:', {
    sourceId: entityData?.source?.id,
    sourceName: entityData?.source?.name,
    fullEntityData: entityData,
    source: entityData?.source  // Added this to see the full source object
  });

  const storyId = processStoryIdForDB(entityData?.url || data.url);
  const formattedDate = date ? date : new Date(entityData?.publishedAt || entityData?.pubDate).toString().slice(0, 15);
  
  const entityInfo = { id: storyId, type: ENTITY_TYPE['story'] };
  const { storiesSupporting, storiesOpposing } = userAccount;
  
  const userSupportsStory = 
    storiesSupporting.includes(storyId) ? true :
    storiesOpposing.includes(storyId) ? false : null;
  
  const [state, setState] = useState({
    numOfChar: 0,
    showOptionModal: false,
    showCommentWindow: false,
  });

  const [renderIframe, updateRenderIframe] = useState(false);
  const updateIframeRender = () => updateRenderIframe(!renderIframe);
  // It's a comment but we are returning story data

  const [ newCommentAdded ] = useState(false);
  
  const ref = useRef(null);
  // to determine story content layout
  useEffect(() => {
    const div: HTMLDivElement = (ref.current as unknown as HTMLDivElement);
    const divWidth = div.offsetWidth;
    const charSize = 6;
    const numOfCharPerLine = Math.floor(divWidth / charSize);
    const numOfChar = numOfCharPerLine * 2;
    if (state.numOfChar !== numOfChar) {
      setState({ ...state, numOfChar });
    }
  }, [state, setState, ref]);

  const renderContent = entityData.content?.slice(0, state.numOfChar);

  const localStateRefresh =  (storyId: string) => {
    return async () => {
      const res = await getStoryData(storyId);
      if (res) {
        dispatch(update_feed_item_data({ id: storyId, data: res.data }))
      }
    }
  }

  const onSupport = () => {
    const userSupports = storiesSupporting.includes(storyId);
    handleSupport({
      // local state refresh
      localStateRefresh: localStateRefresh(storyId),
      entityInfo,
      entityData,
      userAccount,
      doesSupport: !userSupports || null,
      dispatch
    })
  }

  const onOppose = () => {
    const userOpposes = storiesOpposing.includes(storyId);
    handleSupport({
      localStateRefresh: localStateRefresh(storyId),
      entityInfo,
      entityData,
      userAccount, 
      doesSupport: userOpposes && null,
      dispatch
    })
  }
  
  return (
    <>
      <section 
        className={isFeedComment ? `news-wrapper` : `news-wrapper not-comment`} 
        key={idx}
      > 
        {/* <HairLine appData={appData} /> */}
        <NewsImageContainer entityData={entityData} />

        {/* news title */}
        <NewsTitle 
          entityData={entityData} 
          idx={idx} 
          updateIframeRender={updateIframeRender}
        />

        {/* news content */}
        <NewsContent 
          date={formattedDate}
          entityData={entityData}
          idx={idx}
          ref={ref}
          renderContent={renderContent}
          showSourceIcon={showSourceIcon}
        />
        
        <div className="user-interaction-container">     
          <CommentAndSupport
            userSupportsEntity={userSupportsStory} 
            tickerData={{ supporting: appData.supporting, opposing: appData.opposing }}
            handleSupport={onSupport} 
            handleOppose={onOppose}
            openCommentWindow={() => setState( prev => ({ ...prev, showCommentWindow: true}))}
            theme={"light"}
          />
          {/* view all comments link */}
        </div>
        <div className="number-of-comments">
        {
          showCommentFeature && appData?.numOfComments ? 
            <Link to={`/comments/story/${storyId}`} className="view-comment-container">
              View {appData.numOfComments} {appData.numOfComments > 1 ? 'comments' : 'comment'}
            </Link>
            : null
        }
        </div>
      </section>

      { 
        state.showOptionModal ?
        <OptionModal
          handleCloseModal={() => {
            setState({ ...state, showOptionModal: false });
          }}
          handleShowCommentWindow={() => {
            setState({
              ...state,
              showOptionModal: false,
              showCommentWindow: true,
            });
          }}
        />
        : 
        null
      }

      { 
        state.showCommentWindow ? 
        <CommentWindow
          closeCommentWindow={() => setState(prev => ({ ...prev, showCommentWindow: false }))}
          doesSupport={userSupportsStory}
          entityData={entityData}
          entityInfo={entityInfo}
          handleOppose={onOppose}
          handleSupport={onSupport}
          newCommentAdded={newCommentAdded}
          trackCommentAdded={localStateRefresh(storyId)}
        /> : 
        null
      }
    </>
  );
}

export default Story;