import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserCredential, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from "../../../firebase/instance/firebase-instance";
import TextInput from '../../shared/text-input/TextInput';
import PrimaryButton from '../../shared/primary-button/PrimaryButton';
import HeaderText from '../../shared/header-text/HeaderText';
import '../onboarding/name-and-email/NameAndEmail.css';
import './SignIn.css';

import { authUtils } from '../../../firebase/instance';
import { PASSWORD_FORGET, SIGN_UP } from '../../../constants/routes';
// import { auth } from 'firebase-functions/v1';

const SignIn = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: null,
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { email, password } = state;

    authUtils
      .doSignInUserWithEmailAndPassword(email, password)
      .catch((error) => {
        console.error(error);
        setState({ ...state, error: error.code });
      });
  };

  const isInvalid = state.email === "" || state.password === "";

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // Perform the sign-in with popup
      const result: UserCredential = await signInWithPopup(auth, provider);
  
      // Extract user and additionalUserInfo
      const user = result.user;
      const additionalUserInfo = result as any; // Type assertion
      const isNewUser = additionalUserInfo.additionalUserInfo?.isNewUser;
  
      console.log("Google sign-in successful: ", user);
  
      if (isNewUser) {
        // Handle new user logic
        console.log("New user signed in.");
        // Example: Save user data to your database
      } else {
        // Handle existing user logic
        console.log("Existing user signed in.");
        // Example: Retrieve user data from your database
      }
    } catch (error) {
      console.error("Google sign-in error: ", error);
      // Handle errors appropriately
    }
  };


  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);



  return (
    <form
      className="signin-form-container section-container"
      onSubmit={handleSubmit}
    >
      <div className="name-email-wrapper">
        <HeaderText
          styleColor="light"
          headerText="ENGAGE WITH PERSONALIZED POLITICS & NEWS."
          fontSize="2rem"
        />

        <div>
          <TextInput
            styleColor="light"
            placeholder="EMAIL"
            onTextInputChange={(event) => {
              setState({ ...state, email: event.target.value });
            }}
          />
          <TextInput
            styleColor="light"
            placeholder="PASSWORD"
            type="password"
            onTextInputChange={(event) => {
              setState({ ...state, password: event.target.value });
            }}
            errorMessage={state.error ? "Invalid email and/or password.*" : ""}
            errorMessageColor="white"
          />
          </div>

        <div className='PrimaryButton'>
          <PrimaryButton label="LOGIN" onClick={handleSubmit} disabled={isInvalid} />

          <button className="gsi-material-button" onClick={handleGoogleSignIn}>
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                </div>
                <span className="gsi-material-button-contents" style={{fontSize: '14px'}}>Sign in with Google</span>
            </div>
          </button>
        </div>

        <Link to={PASSWORD_FORGET} className="forgot-password-link">
          <span className="forgot-password-text">Forgot your password?</span>
        </Link>

        <div className="mobilize-logo-container">
          <img
            className="mobilize-logo"
            alt="Mobilize Logo"
            src="/img/mobilizeIconWhitetext.svg"
          />
        </div>
      </div>

      <div className="footer">
        <p className="footer-text">Need to create an account?</p>
        <Link to={SIGN_UP}>
          <span className="login-link">Sign Up.</span>
        </Link>
      </div>
    </form>
  );
};

export default SignIn;
